<template>
  <form @submit.prevent="submitForm" @keydown="form.onKeydown($event)" name="event-form" class="flex flex-col space-y-4"
    autocomplete="off">

    <!-- <div v-if="event">
      <pre>{{ JSON.stringify(event, null, 2) }}</pre>
    </div> -->


    <t-input-group :label="$t('event.form.name')">
      <t-input v-model="form.name" required :variant="{ danger: form.errors.has('name') }"
        :placeholder="$t('event.form.placeholder--name')" />
    </t-input-group>

    <t-input-group class="relative">
      <template v-slot:label>{{ $t("event.form.date_from") }}</template>

      <input :type="datetime_mode ? `datetime-local` : `date`" v-model="form.date_from" class="form-input form-date" />


      <button type="button" @click="datetime_mode=!datetime_mode"
        class="flex items-center justify-center flex-shrink-0 p-0.5 rounded-full absolute right-0 top-0 text-xs space-x-1"
        :class="{'text-gray-500' : datetime_mode, 'text-black' : !datetime_mode}">
        <span v-if="!datetime_mode">{{ $t('event.form.include_time') }}</span><span v-else>{{
          $t('event.form.exclude_time') }}</span>
        <icon-clock class="w-4 h-4" :class="{'text-gray-500' : datetime_mode, 'text-black' : !datetime_mode}" />
      </button>

      <!-- <template v-slot:feedback>
        <pre>{{ form.date_from }}</pre>
      </template> -->
    </t-input-group>

    <t-input-group class="relative">
      <template v-slot:label>
        <div class="flex space-x-2">
          <span class="text-xs">
            {{ $t("event.form.date_until") }}
          </span>
          <span class="ml-1 text-xs font-light">{{ $t("form.optional") }}</span>
        </div>
      </template>

      <input :type="datetime_mode ? `datetime-local` : `date`" class="form-input form-date" v-model="form.date_until" />

      <!-- <template v-slot:feedback>
        <pre>{{ form.date_until }}</pre>
      </template> -->
    </t-input-group>

    <t-input-group>
      <template v-slot:label>
        {{ $t("event.form.location") }}
        <span class="ml-1 text-xs font-light">{{ $t("form.optional") }}</span>
      </template>
      <t-input v-model="form.location" :variant="{ danger: form.errors.has('location') }"
        :placeholder="$t('event.form.placeholder--location')" autocomplete="off" />
    </t-input-group>

    <t-input-group>
      <template v-slot:label>
        {{ $t("event.form.notes") }}
        <span class="ml-1 text-xs font-light">{{ $t("form.optional") }}</span>
      </template>
      <t-textarea v-model="form.notes" class="h-32" name="notes" :variant="{ danger: form.errors.has('notes') }"
        :placeholder="$t('event.form.placeholder--notes')" autocomplete="off" />
    </t-input-group>

    <t-button type="submit" :disabled="form_loading">{{
      form_loading
      ? $t("action.saving")
      : this.event
      ? $t("event.btn--update-event")
      : $t("event.btn--save-event")
      }}</t-button>

    <template v-if="event && event.uuid">
      <t-button type="button" :disabled="!event || form_loading || delete_loading" variant="link" class="text-red-500"
        @click="deleteEvent">{{
        delete_loading ? $t("action.deleting") : $t("action.delete")
        }}</t-button>
    </template>
  </form>
</template>

<script>
// import moment from 'moment';
import { Form } from "vform";

import IconClock from "assets/icons/clock.svg";

import Repository from "repositories/RepositoryFactory";
const EventRepository = Repository.get("my-events");

export default {
  name: "EventForm",
  components: {
    IconClock,
  },
  props: {
    event: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    // date_modes: ['date', 'datetime-local'],
    datetime_mode: null,
    form: new Form({
      name: "",
      date_from: null,
      date_until: null,
      location: "",
      notes: "",
    }),
    form_loading: false,
    form_error: "",
    delete_loading: false,
  }),
  mounted() {
    if (this.event) {
      this.datetime_mode = true;
      this.$nextTick(() => {
        this.form.fill(this.event);

        this.date_from_time_cache = this.event.date_from ? this.$moment(this.event.date_from).format('HH:mm') : '00:00';
        this.date_until_time_cache = this.event.date_until ? this.$moment(this.event.date_until).format('HH:mm') : '00:00';

        this.datetime_mode = !(this.date_from_time_cache == '00:00' && this.date_until_time_cache == '00:00');
      });
    } else {
      this.datetime_mode = false;
    }
  },
  watch: {
    datetime_mode(from, to) {
      if (from == null) return;
      if(from == to) return;

       if(!to){
        // add T00:00
        if(this.form.date_from) {
          this.form.date_from = this.form.date_from.split("T")[0] + 'T' + (this.date_from_time_cache || '00:00');
        }
        // add T23:59
        if (this.form.date_until) {
          this.form.date_until = this.form.date_until.split("T")[0] + 'T' + (this.date_until_time_cache || '23:59');
        }
      } else {
        // remove T
        if (this.form.date_from) {
          this.date_from_time_cache = this.form.date_from.split("T")[1].split('+')[0] || '00:00';
          this.form.date_from = this.form.date_from.split("T")[0];
        }
        if (this.form.date_until) {
          this.date_until_time_cache = this.form.date_until.split("T")[1].split('+')[0] || '00:00';
          this.form.date_until = this.form.date_until.split("T")[0];
        }

      }
    }
  },
  methods: {
    /* dateFieldFormatter(value) {
      return moment(value).format('YYYY-MM-DD' + (this.datetime_mode ? '[T]HH:mm' : ''));
    }, */
    checkEventDateTimeType() {
      this.datetime_mode = !this.form.date_from.split('T').length > 1;
    },
    checkEventHours() {
      const MIDNIGHT_TIME = "00:00:00";
      const eventFromTime = this.event.date_from.split("T")[1] || MIDNIGHT_TIME;
      if (!eventFromTime.length || eventFromTime == MIDNIGHT_TIME){
        this.datetime_mode = false;
      } else {
        this.datetime_mode = true;
      }
    },
    deleteEvent() {
      if (!this.event) return;
      this.delete_loading = true;
      if (!confirm(this.$i18n.t("event.confirm--delete"))) {
        this.delete_loading = false;
        return;
      }
      EventRepository.delete(this.event.uuid)
        .then(() => {
          this.$router.push({ name: "my-events" });
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
        })
        .then(() => {
          this.delete_loading = false;
        });
    },
    submitForm() {
      this.form_loading = true;
      if (this.event) {
        this.updateEvent(this.event.uuid);
      } else {
        this.createEvent();
      }
    },
    createEvent() {
      const { name, date_from, date_until, location, notes } = this.form;

      EventRepository.create({
        name,
        date_from,
        date_until,
        location,
        notes,
      })
        .then((response) => {
          this.$router.push({
            name: "my-event",
            params: { uuid: response.data.event.uuid },
          });
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
        })
        .then(() => {
          this.form_loading = false;
        });
    },
    updateEvent(uuid) {
      const { name, date_from, date_until, location, notes } = this.form;
      EventRepository.update(uuid, {
        name,
        date_from,
        date_until,
        location,
        notes,
      })
        .then((r) => {
          this.$emit("event-updated", r.data.event);
          this.$emit("success");
          this.form.reset();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
        })
        .then(() => {
          this.form_loading = false;
        });
    },
  },
};
</script>
