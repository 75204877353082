<template>
  <div>
    <button
      type="button"
      class="
        flex flex-row
        w-full
        p-3
        space-x-2
        text-left
        rounded-lg
        appearance-none
        focus:outline-none
      "
      :class="{
        'text-white bg-brand-tipblue ring-brand-dark': dark,
        'bg-form-tip text-brand-tipblue ring-white': !dark,
        'opacity-75 ring ring-inline': !!modalShown,
      }"
      @click="openModal"
    >
      <div class="flex-shrink-0">
        <icon-tip-useful
          v-if="used"
          class="w-8 h-8"
          :class="{ 'text-white': dark }"
        />
        <!-- <icon-tip-useful v-else-if="!used" class="w-8 h-8" /> -->
        <icon-tip v-else class="w-8 h-8" :class="{ 'text-white': dark }" />
      </div>
      <q class="italic font-semibold leading-6">{{ tip.title }}</q>
    </button>
    <modal :show="modalShown" @close="closeModal">
      <div class="p-6 space-y-2">
        <h5 class="font-semibold">{{ tip.title }}</h5>
        <div v-html="tip.description || $t('tips.no_description')"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "components/Modal";
import IconTip from "assets/icons/tip.svg";
import IconTipUseful from "assets/icons/tip-useful.svg";

export default {
  name: "TipBlock",
  components: {
    Modal,
    IconTip,
    IconTipUseful,
  },
  props: {
    tip: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    used: {
      type: [Boolean, Number],
      default: null,
    },
  },
  data: () => ({
    modalShown: false,
  }),
  methods: {
    openModal() {
      this.modalShown = true;
    },
    closeModal() {
      this.modalShown = false;
    },
  },
};
</script>
